//LP form用ライブラリです。

//機能
//・バリデーション
//  - エラーメッセージや必須項目はHTML側で設定
//  - フォーカスが外れたときバリデーションチェック
//  - エラーがない場合にsubmitボタン活性化
//・確認画面へのデータ流し込み
//・APIを叩いてメール送信

import serialize from 'form-serialize'

const lpForm = (() => {
  return class LpForm {

    constructor(rootElement, options) {

      this.form = this.convertElement(rootElement);
      if (!this.form) return;

      const defaultOptions = {
        storageName: "form-session-storage", //セッションストレージ名
        confirmURL: "/contact/confirm/", //確認画面のURL
        finishURL: "/contact/finish/", //完了画面画面のURL
        sendAPI: "/api/contacts/send", //メール送信用のAPI_URL
        apiKey: "GrVCBC8RDj6Y4rLaMf-zBE5GR3m3_mde", //フォームのAPIキー
      }

      this.options = this.mergeOptions(defaultOptions, options)

      this.inputElements = this.form.querySelectorAll("input,select,textarea");
      this.submit = this.form.querySelector('[type="submit"]');


      this.init();

    }

    init() {
      this.setUpFormstorage();
      this.validateSubmit();
      this.setUpAttribute();
      this.handleEvent();
      this.validateExecution(); //ストレージデータがある場合はバリデーションを実行する
    }

    mergeOptions(defaultOptions, options) {
      const mergeOptions = Object.assign(defaultOptions, options || {});
      return mergeOptions
    }

    setUpFormstorage() {
      //セッションストレージに対象のフォームのデータがある場合はセットする
      this.applyState(sessionStorage.getItem(this.options.storageName))
    }

    //必要な属性をセットする
    setUpAttribute() {
      this.inputElements.forEach((currentInput) => {
        //pattern（正規表現）に指定があればセット
        if (currentInput.hasAttribute("data-validation-pattern")) {
          const pattern = currentInput.getAttribute("data-validation-pattern")
          currentInput.setAttribute("pattern", this.getValidateType(pattern))
        }
      })
    }

    //バリデーション用正規表現
    getValidateType(v) {
      let type;
      switch (v) {
        //全角カナ
        case "zenkana":
          type = "[ァ-ンヴー|　| ]+$";
          break;
        //電話番号
        case "tel":
          type = "\\d{2,4}-?\\d{2,4}-?\\d{3,4}";
          break;
        //電話番号 : ハイフン無し
        case "tel-not-hyphen":
          type = "^[0-9]{8,11}$";
          break;
      }
      return type;
    }

    //バリデーションを実行する
    validateExecution() {

      if(!sessionStorage.getItem(this.options.storageName)) return
      this.runValidation()

    }

    //バリデーションを実行
    runValidation() {

      this.inputElements.forEach((currentInput) => {
        const targetName = currentInput.getAttribute("name");
        const messageArea = this.form.querySelector(
          `[data-validation="${targetName}"]`
        );

        const invalidMessage = currentInput.getAttribute("data-invalid-text");

        const hasValidateMessage =
          messageArea !== null && currentInput.hasAttribute("data-invalid-text");
        const isValid = currentInput.validity.valid;

        currentInput.setAttribute("data-is-valid", isValid);

        if (hasValidateMessage) {
          messageArea.innerHTML = isValid ? "" : invalidMessage;
        }

        //グループチェックボックスのバリデーション
        if (currentInput.getAttribute("type") == "checkbox") {
          this.checkboxGroupValidation(targetName, messageArea)
          this.validateSubmit();
        }

      });

    }

    //イベントを登録する。
    handleEvent() {
      this.handleValidation(this.inputElements);
      this.handleSubmit(this.submit);
    }

    //バリデーションに関するイベントを登録する。
    handleValidation(input) {
      input.forEach((currentInput) => {
        // 入力内容が変更されたらメッセージを表示
        currentInput.addEventListener("change", this.displayValidation.bind(this));
        currentInput.addEventListener("blur", this.displayValidation.bind(this));

        // 送信ボタンのバリデーション操作
        currentInput.addEventListener("change", this.validateSubmit.bind(this));
      });
    }

    //送信ボタンに関するイベントを登録する。
    handleSubmit(submit) {
      submit.addEventListener("click", this.pressSubmit.bind(this));
    };

    //バリデーションメッセージを表示します。
    displayValidation(event) {
      const targetInput = event.target;
      const targetName = targetInput.getAttribute("name");
      const invalidMessage = targetInput.getAttribute("data-invalid-text");
      const messageArea = this.form.querySelector(
        `[data-validation="${targetName}"]`
      );
      const hasValidateMessage =
        messageArea !== null && targetInput.hasAttribute("data-invalid-text");
      const isValid = targetInput.validity.valid;

      targetInput.setAttribute("data-is-valid", isValid);

      if (hasValidateMessage) {
        messageArea.innerHTML = isValid ? "" : invalidMessage;
        messageArea.setAttribute("aria-hidden", "false")
      }

      //グループチェックボックスのバリデーション
      if (targetInput.getAttribute("type") == "checkbox") {
        this.checkboxGroupValidation(targetName, messageArea)
      }

      return;
    }

    //複数のチェックボックスのバリデーション
    checkboxGroupValidation(name, messageArea) {
      //[data-checkbox-group-validation]がなければグループではないので終了
      const checkboxGroup = document.querySelectorAll(`[name="${name}"][data-checkbox-group-validation]`)
      if (!(checkboxGroup.length > 0)) return

      const targets = document.querySelectorAll(`[name="${name}"]`)
      const invalidMessage = targets[0].getAttribute("data-invalid-text");
      const hasValidateMessage =
        messageArea !== null && targets[0].hasAttribute("data-invalid-text");

      const checkedTargets = document.querySelectorAll(`[name="${name}"]:checked`)

      if (checkedTargets.length > 0) {
        targets.forEach((target) => {
          target.removeAttribute("required")
          target.removeAttribute("aria-required")
          target.setAttribute("data-is-valid", "true");
        })
        if (hasValidateMessage) {
          messageArea.innerHTML = "";
        }
      } else {
        targets.forEach((target) => {
          target.setAttribute("required", "")
          target.setAttribute("aria-required", "true")
          target.setAttribute("data-is-valid", "false");
        })
        if (hasValidateMessage) {
          messageArea.innerHTML = invalidMessage;
        }
      }
    }



    //フォームの内容に応じて送信ボタンの状態を変えます。
    validateSubmit() {
      const isValid = this.form.checkValidity();
      const submitButton = this.submit;
      const messageArea = this.form.querySelector('[data-validation="submit"]');
      const invalidMessage = this.submit.getAttribute("data-invalid-text");

      submitButton.setAttribute("aria-disabled", !isValid);
      messageArea.innerHTML = isValid ? "" : invalidMessage;

      return;
    }

    //送信ボタン押下時の挙動
    pressSubmit(event) {
      event.preventDefault();
      this.runValidation()

      //フォームの入力内容のチェック 問題なければtrue 誤りがあればfalse
      const isValid = this.form.checkValidity();
      //フォームの入力データを格納
      const formData = this.getFormDataObject();
      console.log(formData)
      //フォームの入力データのjsonを格納
      const formData_json = JSON.stringify(formData);

      //入力内容に誤りがあった場合
      if (!isValid) {
        event.preventDefault();
        return
      }

      //確認画面かのチェック
      // const isConfirm = this.submit.classList.contains('js-lpForm-confirm')
      const isConfirm = this.submit.getAttribute('data-to-confirm')

      if (isConfirm == 'true') {
        sessionStorage.setItem(this.options.storageName, formData_json);
        // this.formstorage.save();
        window.top.location.href = this.options.confirmURL;
        event.preventDefault();
        return
      }

      //以下、送信処理 ==================================

      //APIに渡すフォームデータをを作成
      const sendData = new FormData(this.form);
      //API Keyをフォームデータに追加
      sendData.append('key', this.options.apiKey);

      //APIを叩く処理の関数
      const fetchSendFunc = (url, sendData) =>
        fetch(url, {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          body: sendData,
        });

      const sendMail = async () => {
        const response = await fetchSendFunc(this.options.sendAPI, sendData);
        return response;
      }

      //送信処理実行
      sendMail()
        .then(response => {

          //ネットワークエラー以外でもcatchでエラー処理
          if (!response.ok) {
            console.error('response.ok:', response.ok);
            console.error('response.status:', response.status);
            console.error('response.statusText:', response.statusText);
            throw new Error(response.statusText);
          }

          //以下、成功時の場合
          if (response.status === 200) {
            console.log('success')
            console.log(response.status);
            // sessionStorage.removeItem(this.options.storageName); //wifiエラスタモではお問い合わせフォームのアフィリエイト対応のためストレージを削除しない
            window.top.location.href = this.options.finishURL;
          }
        })
        .catch(err => {
          // fetchでcatchに入る場合はネットワークエラーだが、400系や500系のエラーでもここに入るように上で記述している
          console.log(err);
          alert(
            '送信に失敗しました。インターネットに正しく接続されていることを確認して再度送信ください。'
          )
        });

      // event.preventDefault();
      // return;
    };

    //フォームの入力内容を取得しオブジェクトを返す（確認画面用のデータ） ※APIに渡すデータではない
    getFormDataObject() {

      const object = serialize(this.form, { hash: true });

      // const formData = new FormData(this.form);
      // const object = {};

      // for (let entry of formData.entries()) {
      //   let key = entry[0];
      //   let value = entry[1];
      //   if (value) {
      //     object[key] = value;
      //   }
      // }

      return object;
    }


    convertElement(obj) {
      if (obj instanceof HTMLElement) {
        return obj
      }
      // if (obj instanceof jQuery) {
      //   return obj[0]
      // }
      return document.querySelector(obj);
    }

    //セッションストレージの内容をフォームにセット
    applyState(str) {
      const obj = JSON.parse(str);
      // const obj = queryString.parse(str.replace(/^"(.*)"$/, "$1"));
      for (const key in obj) {
        let flag = false;
        // console.log(key)
        const target = this.form.querySelector(`[name="${key}"]`);
        const targets = this.form.querySelectorAll(`[name="${key}"]`);

        if (!target) {
          continue;
        }


        if (flag) {
          continue;
        }

        if (targets && targets.length > 1) {
          const arr = obj[key];
          [].forEach.call(targets, (tar, index) => {
            if (tar.type === 'checkbox') {
              if (arr.forEach) {
                arr.forEach(item => {
                  if (item === tar.value) {
                    tar.checked = true;
                  }
                });
              } else {
                if (arr === tar.value) {
                  tar.checked = true;
                }
              }
            } else if (tar.type === 'radio') {
              if (tar.value === arr) {
                tar.checked = true;
              }
            }
          });
          continue;
        }

        if (target.type === 'radio' || target.type === 'checkbox') {
          if (obj[key] === target.value) {
            target.checked = true;
          }
        } else {
          target.value = obj[key];
        }
      }
    }

  }

})();

export default lpForm;


// new LpForm('#lp-form', {
//   storageName: "form-session-storage", //任意のセッションストレージ名
//   finishURL: "/contact/finish/", //任意の完了画面画面のURL
//   sendAPI: "/api/contacts/send", //メール送信用のAPI_URL
// }) 